<template>
  <div>
    <b-row class="justify-content-center mb-4">
      <p class="p4">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>
    <div v-show="!this.loading">
      <AccordionList
        titleKeyName="bankDescription"
        incrementalTitle="Dados bancários"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
      />
      <BankInformationAlterModal :loadedFormData="this.tableItems[0]" />
      <div class="mt-5" v-if="tableItems && tableItems.length > 0">
        <b-button
          class="my-3 mr-3"
          variant="primary"
          v-b-modal:bank-alter-modal
        >
          Alterar dados bancários
        </b-button>
      </div>
    </div>
    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import { getBankInformation } from "../../../../services/payment-options/paymentOptionsService";
import BankInformationAlterModal from "../forms/bank-information/BankInformationAlterModal.vue";

const tableFields = [
  { key: "bankDescription", label: "BANCO", tdClass: "text-right" },
  { key: "agency", label: "AGÊNCIA", tdClass: "text-right" },
  { key: "account", label: "CONTA CORRENTE", tdClass: "text-right" },
];

const BankInformation = {
  name: "bank-information",
  components: { AccordionList, BankInformationAlterModal },
  setup() {
    return { tableFields };
  },
  mounted() {
    const crm = this.$store.getters.getUserName;
    this.getUserAddreses(crm);
  },
  data() {
    return {
      tableItems: [],
      loading: true,
    };
  },
  methods: {
    async getUserAddreses(getfromLoginCrm) {
      try {
        this.loading = true;
        this.tableItems = this.buildBankDescription(
          await getBankInformation(getfromLoginCrm)
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    buildBankDescription(tableItems) {
      return [
        {
          ...tableItems,
          bankDescription: `${tableItems.bankCode} - ${tableItems.bank}`,
        },
      ];
    },
  },
};

export default BankInformation;
</script>

<style lang="scss">
.addresses-container td::before {
  text-align: left !important;
}

.addresses-container td:first-child {
  border-color: #eff2f5;
}
</style>
