<template>
  <div>
    <AlterFormLayout
      modalId="bank-alter-modal"
      formTitle="Alterar dados bancários"
      attentionModalId="bank-attention-modal"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de alteração"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
      ref="alter-modal"
    >
      <template #content>
        <BankInformationForm :loadedFormData="$props.loadedFormData" />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de alteração de dados bancários pessoa física foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados inseridos serão analisados e cadastrados após a validação."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import BankInformationForm from "./BankInformationForm.vue";
import AlterFormLayout from "../../../../../layouts/AlterFormLayout.vue";
import { updateBankInformation } from "../../../../../services/payment-options/paymentOptionsService";
import SuccessModal from "../../../../../components/base-modals/SuccessModal.vue";

export default {
  props: ["loadedFormData"],
  components: {
    AlterFormLayout,
    BankInformationForm,
    SuccessModal,
  },
  data() {
    return {
      selectedOption: null,
      protocol: "",
    };
  },
  methods: {
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async onSubmit(formData) {
      this.protocol = await updateBankInformation(formData, formData.files);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
  },
};
</script>
