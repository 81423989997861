<template>
  <div>
    <b-row class="px-3">
      <p class="p4">
        <i class="fas fa-info-circle"></i>
        Importante: Para comprovação dos dados, utilize foto do cartão, cheque,
        extrato ou mesmo print do aplicativo.
      </p>
    </b-row>
    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          BANCO
        </b-col>
        <b-col>
          <b-form-select
            v-model="bankSelected"
            :options="bankList"
            v-on:change="onChangeBankSelect"
            disabled-field="notEnabled"
            size="lg"
            placeholder="Selecione item"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          AGÊNCIA
        </b-col>
        <b-col>
          <b-form-input
            v-model.lazy.trim="form.agency"
            :state="validateField('agency')"
            placeholder="Digite"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          CONTA CORRENTE
        </b-col>
        <b-col>
          <b-form-input
            v-model.lazy.trim="form.account"
            :state="validateField('account')"
            placeholder="Digite"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="onChangeFiles"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É necessário inserir os comprovantes.'"
            :state="validateFieldFile('files')"
            :required="true"
            v-model.lazy.trim="form.files"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import { getBanks } from "../../../../../services/payment-options/paymentOptionsService";
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";

const DefaultForm = {
  bank: null,
  agency: null,
  account: null,
  files: null,
  originalData: null,
};

export default {
  props: ["loadedFormData", "newRegister"],
  components: { UploadFile },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },
  mounted() {
    this.getBankOptions();
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
      bankList: [],
      bankSelected: null,
    };
  },
  methods: {
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      this.form = { ...DefaultForm, ...this.$props.loadedFormData };

      this.formData = {
        form: { ...this.$props.loadedFormData },
        onSubmitHandler: this.onSubmit,
      };
      this.form.originalData = this.formData.form;
    },
    async getBankOptions() {
      try {
        this.bankList = this.buildBankOptions(await getBanks());
        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("getBanks", error);
      }
    },
    buildBankOptions(formDataOptions) {
      this.bankSelected = formDataOptions.find(
        (b) => b.bankCode == this.form.bankCode
      );
      return formDataOptions.map((formData) => ({
        value: formData,
        text: `${formData.bankCode} - ${formData.bank}`,
      }));
    },
    onChangeBankSelect(formData) {
      const form = { ...this.form, ...formData };
      this.form = form;
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    onChangeFiles(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
